import React from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from 'react-helmet';
import ShareImg from "../images/share.png";

export default () => (
  <Layout>
      <section class="py-24 container mx-auto">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Thank you!</h1>
      <p class="mb-8 leading-relaxed">We have received your message and will reach out to you as soon as possible.</p>
      </section>

    <Helmet>
      <meta charSet="utf-8" />
      <title>Thank You | Hollis Meddings Group</title>
      <meta name="description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta property="og:title" content="Hollis Meddings Group"/>
      <meta property="og:description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta property="og:image" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:url" content="https://www.hollismeddings.com"/>
      <meta name="twitter:card" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:site_name" content="Hollis Meddings Group"/>
      <meta name="twitter:image:alt" content="Hollis Meddings Group"/>
    </Helmet>
  </Layout>
);
